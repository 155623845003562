import React, { useState, useCallback } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import Slide from "react-reveal/Slide";
import BackgroundImage from "gatsby-background-image";

const { renderToString } = require("react-dom/server");

const ListItem = styled.li`
  list-style-type: none;
  font-size: 20px;
  display: inline-block;
  width: 330px;
  @media(max-width:500px){
    font-size:17px;
  }
  &:before {
    content: url(data:image/svg+xml,${encodeURIComponent(
      renderToString(<FontAwesomeIcon icon={faCheck} />)
    )});
    width: 20px;
    display: block;
    float: left;
    position: absolute;
    margin-left: -31px;
  }
  span{
    display:block;
    float:left; 
    margin-top:3px;
  }
`;

const CircleSave = styled.div`
  background: ${({ color }) => color};
  &:after,
  &:before {
    background: ${({ color }) => color};
  }
`;

const HeaderTwo = styled.h2`
  display: block;
  width: 100%;
  text-align: center;
  grid-column-start: 1;
  grid-column-end: 4;
  margin-top: 50px;
  margin-bottom: 150px;
  font-size: 50px;
  font-weight: 100;
  text-transform: uppercase;
  letter-spacing: 3px;
  @media (max-width: 1500px) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  @media (max-width: 960px) {
    grid-column-start: initial;
    grid-column-end: initial;
    font-size: 30px;
  }
  @media (max-width: 500px) {
    font-size: 22px;
    margin-top: 0;
    margin-bottom: 75px;
  }
`;

const Testimonials = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 1500px) {
    display: block;
  }
  margin-bottom: 100px;
  h1 {
    grid-column-start: 1;
    grid-column-end: 4;
    margin-top: 50px;
    margin-bottom: 50px;
    letter-spacing: 3px;
    text-align: center;
    font-size: 50px;
    font-weight: 100;
    text-transform: uppercase;
    @media (max-width: 500px) {
      font-size: 30px;
    }
  }
`;

const TestimonialsScroll = styled.div`
  transform: translateY(-150px);
`;

const Card = styled.div`
  height: 300px;
  h3,
  p {
    color: #ffffff;
    padding: 25px;
    margin: 0;
  }
  @media (max-width: 960px) {
    height: 400px;
    margin-bottom: 50px;
  }
  .fullScreen {
    position: fixed !important;
    opacity: 0.99;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh !important;
    overflow: scroll;
    top: 0;
    z-index: 100000000;
    left: 0;
    opacity: 1 !important;
    &:before {
      position: fixed !important;
    }
    div {
      height: 100vh;
      display: grid;
      justify-items: center;
      grid-template-rows: 2fr 1fr 1fr 7fr;
      .svg-inline--fa {
        height: 3em;
        width: 3em;
        color: #ffffff;
      }
    }
    p {
      font-size: 18px;
      line-height: 36px;
      width: 50%;
      @media (max-width: 600px) {
        width: 90%;
      }
    }
    h2 {
      text-align: center;
      font-size: 40px;
      color: #ffffff;
      letter-spacing: 3px;
      padding: 0;
      margin: 0;
    }
    a {
      width: 100px;
      height: 100px;
      text-align: center;
      background: grey;
      display: grid;
      align-items: center;
      justify-items: center;
      border-radius: 50%;
      margin-top: 10px;
    }
  }
`;

const FullCard = styled.div`
  background-image: linear-gradient(#585858, #585858c7),
    url(${({ background }) => background});
  position: fixed;
  //height:100vh;
  width: 100%;
  left: 0;
  top: 0;
  background-size: cover;
  z-index: 10000000000;
  animation: 0.5s ease-out 0s 1 slideInFromLeft;
  overflow: scroll;
  div {
    height: 100vh;
    display: grid;
    justify-items: center;
    grid-template-rows: 2fr 1fr 1fr 7fr;
    .svg-inline--fa {
      height: 3em;
      width: 3em;
      color: #ffffff;
    }
  }
  p {
    font-size: 18px;
    line-height: 36px;
    width: 50%;
    @media (max-width: 600px) {
      width: 90%;
    }
  }
  h2 {
    text-align: center;
    font-size: 40px;
    color: #ffffff;
    letter-spacing: 3px;
    padding: 0;
    margin: 0;
  }
  a {
    width: 100px;
    height: 100px;
    text-align: center;
    background: grey;
    display: grid;
    align-items: center;
    justify-items: center;
    border-radius: 50%;
    margin-top: 10px;
  }
`;

const SmallCard = styled.div`
  .bg-cover {
    height: 300px;
    margin: 0;
    padding: 0;
    display: grid;
    align-items: center;
    transition: all 1s ease;
    @media (max-width: 960px) {
      height: 400px;
    }
    &:hover {
      transform: translateY(-20px);
      cursor: pointer;
      transition: all 0.2s ease;
    }
    h2 {
      color: #ffffff;
      text-align: center;
    }
    h3 {
      line-height: 32px;
      text-align: left;
      font-weight: 100;
      position: relative;
      font-size: 20px;
      padding: 25px 50px;
      @media (max-width: 450px) {
        font-size: 16px;
        line-height: 28px;
      }
      span {
        font-size: 52px;
        letter-spacing: 2px;
        padding: 15px;
        position: absolute;
        quotes: "“" "”" "‘" "’";
        //font-family:Catamaran;
        &:first-child {
          transform: translateY(-25px);
          left: 0;
        }
        &:first-child:before {
          content: open-quote;
        }
        &:last-child:before {
          content: close-quote;
        }
      }
    }
  }
`;

const PricingCard = styled.section`
  .button {
    &:hover {
      background: grey;
    }
  }
`;

const GroupPricingScroll = styled.div`
  transform: translateY(-150px);
`;

const ProfileImage = styled.img`
  clip-path: circle(75px at center);
  width: 150px;
  object-fit: cover;
  height: 150px;
`;

const BackgroundNoImage = styled.div`
  background: ${({ color }) => color};
`;

const travelPackage = (pricingSlug) => {
  console.log(pricingSlug);
  console.log("test");
  window.location = "/pricing/" + pricingSlug;
};

const travelPackageGroup = (pricingSlug) => {
  console.log(pricingSlug);
  console.log("test");
  window.location = "/group-pricing/" + pricingSlug;
};

const TestimonialCard = ({ testimonial }) => {
  const [showFull, setShowFull] = useState(false);

  const backgroundFluidImageStack = [
    testimonial.testimonialImage.fluid,
    `linear-gradient(0deg, rgba(111,111,111,0.6979166666666667) 100%, rgba(0,0,0,0.43461134453781514) 100%)`,
  ].reverse();

  const backgroundFluidImageStack1 = [
    testimonial.testimonialImage.fluid,
    `linear-gradient(0deg, rgba(111,111,111,0.6979166666666667) 100%, rgba(0,0,0,0.43461134453781514) 100%)`,
  ].reverse();

  const handleClick = useCallback(() => {
    //setShowFull(showFull=>!showFull)
    setShowFull((showFull) => !showFull);
    //setShowFull(s=>!s)//If you want toggle behaviour
  }, []);
  const closeClick = (e) => {
    e.preventDefault();
    setShowFull(true);
  };
  return (
    <Card onClick={handleClick}>
      {showFull ? (
        <>
          {testimonial.imageOn ? (
            <BackgroundImage
              Tag="section"
              fluid={backgroundFluidImageStack1}
              className="bg-cover fullScreen"
            >
              <div>
                <a onClick={closeClick} href="#">
                  <FontAwesomeIcon icon={faTimes} />
                </a>
                <h2>{testimonial.name}</h2>

                {testimonial.profileImage ? (
                  <ProfileImage src={testimonial.profileImage.url} />
                ) : (
                  <img src="https://www.datocms-assets.com/19299/1576799578-profile.png" />
                )}

                <p>{testimonial.fullLengthQuote}</p>
              </div>
            </BackgroundImage>
          ) : (
            <>
              {testimonial.backgroundColor && (
                <BackgroundNoImage
                  Tag="section"
                  className="bg-cover fullScreen"
                  color={testimonial.backgroundColor.hex}
                >
                  <div>
                    <a onClick={closeClick} href="#">
                      <FontAwesomeIcon icon={faTimes} />
                    </a>
                    <h2>{testimonial.name}</h2>

                    {testimonial.profileImage ? (
                      <ProfileImage src={testimonial.profileImage.url} />
                    ) : (
                      <img src="https://www.datocms-assets.com/19299/1576799578-profile.png" />
                    )}

                    <p>{testimonial.fullLengthQuote}</p>
                  </div>
                </BackgroundNoImage>
              )}
            </>
          )}
        </>
      ) : (
        //  {/* </FullCard> */}
        <SmallCard>
          {testimonial.imageOn ? (
            <BackgroundImage
              Tag="section"
              fluid={backgroundFluidImageStack}
              className="bg-cover"
            >
              <div>
                <h2>{testimonial.name}</h2>
                <h3>
                  <span></span>
                  {testimonial.shortQuote}
                  <span></span>
                </h3>
              </div>
            </BackgroundImage>
          ) : (
            <>
              {testimonial.backgroundColor && (
                <BackgroundNoImage
                  color={testimonial.backgroundColor.hex}
                  Tag="section"
                  className="bg-cover"
                >
                  <div>
                    <h2>{testimonial.name}</h2>
                    <h3>
                      <span></span>
                      {testimonial.shortQuote}
                      <span></span>
                    </h3>
                  </div>
                </BackgroundNoImage>
              )}
            </>
          )}
        </SmallCard>
      )}
    </Card>
  );
};

// Begin Component

const IndexPage = ({ data }) => {
  return (
    <>
      <Layout>
        <div id="pricing"></div>

        <PricingCard className="pricing">
          <HeaderTwo>{data.datoCmsHome.individualPricing}</HeaderTwo>
          {data.allDatoCmsPricing.edges.map(({ node: pricing }) => (
            <Slide key={pricing.id} right>
              {/* // Pricing Div Wrapper         */}
              <div
                style={
                  pricing.turnPackageOnOrOff
                    ? { display: "block" }
                    : { display: "none" }
                }
                className={"package " + (pricing.promoWide ? "promo-wide" : "")}
              >
                {/* Turn Package On or Off Start */}
                {pricing.turnPackageOnOrOff === true ? (
                  <figure
                    onClick={() => travelPackage(pricing.slug)}
                    style={{
                      borderColor: pricing.packageBorderColor.hex,
                      boxShadow: `-20px 20px 1px ${pricing.packageShadowColor.hex}`,
                    }}
                    className={"card " + (pricing.promoWide ? "active" : "")}
                  >
                    {pricing.packageSavings ? (
                      <CircleSave
                        color={pricing.packageBorderColor.hex}
                        className="circle-save"
                      >
                        <p>${pricing.packageSavings}</p>
                        <p>savings</p>
                      </CircleSave>
                    ) : null}
                    <h6
                      style={{
                        backgroundColor: pricing.packageBorderColor.hex,
                      }}
                      className="card__title"
                    >
                      <Link to={`/pricing/${pricing.slug}`}>
                        {pricing.title}
                      </Link>
                    </h6>
                    <p
                      style={{ color: pricing.packageBorderColor.hex }}
                      className="price-session"
                    >
                      <span>${pricing.priceAmount}</span> |{" "}
                      <span>{pricing.lengthOfSession}</span>
                    </p>
                    <p className="number-sessions">
                      {pricing.numberOfSessions}
                    </p>
                    <ul className="details-list">
                      {pricing.details.map((detailEntry) => {
                        return (
                          <ListItem key={detailEntry.id}>
                            <span>{detailEntry.task}</span>
                          </ListItem>
                        );
                      })}
                    </ul>
                    <Link
                      style={{
                        backgroundColor: pricing.packageBorderColor.hex,
                      }}
                      className="button book"
                      to={`/pricing/${pricing.slug}`}
                    >
                      Book
                    </Link>
                  </figure>
                ) : null}
                {/* Turn Package On or Off End */}
              </div>
            </Slide>
            // Pricing Div Wrapper Ends
          ))}
        </PricingCard>
        <GroupPricingScroll id="groupPricing"></GroupPricingScroll>
        <PricingCard className="pricing">
          <HeaderTwo>{data.datoCmsHome.groupPricing}</HeaderTwo>
          {data.allDatoCmsGroupPricing.edges.map(({ node: pricing }) => (
            // Pricing Div Wrapper
            <Slide key={pricing.id} right>
              <div
                style={
                  pricing.turnPackageOnOrOff
                    ? { display: "block" }
                    : { display: "none" }
                }
                className={"package " + (pricing.promoWide ? "promo-wide" : "")}
              >
                {/* Turn Package On or Off Start */}
                {pricing.turnPackageOnOrOff === true ? (
                  <figure
                    onClick={() => travelPackageGroup(pricing.slug)}
                    style={{
                      borderColor: pricing.packageBorderColor.hex,
                      boxShadow: `-20px 20px 1px ${pricing.packageShadowColor.hex}`,
                    }}
                    className={"card " + (pricing.promoWide ? "active" : "")}
                  >
                    {pricing.packageSavings ? (
                      <CircleSave
                        color={pricing.packageBorderColor.hex}
                        className="circle-save"
                      >
                        <p>${pricing.packageSavings}</p>
                        <p>savings</p>
                      </CircleSave>
                    ) : null}
                    <h6
                      style={{
                        backgroundColor: pricing.packageBorderColor.hex,
                      }}
                      className="card__title"
                    >
                      <Link to={`/group-pricing/${pricing.slug}`}>
                        {pricing.title}
                      </Link>
                    </h6>
                    <p
                      style={{ color: pricing.packageBorderColor.hex }}
                      className="price-session"
                    >
                      <span>${pricing.priceAmount}</span> |{" "}
                      <span>{pricing.lengthOfSession}</span>
                    </p>
                    <p className="number-sessions">
                      {pricing.numberOfSessions}
                    </p>
                    <ul className="details-list">
                      {pricing.details.map((detailEntry) => {
                        return (
                          <ListItem key={detailEntry.id}>
                            <span>{detailEntry.task}</span>
                          </ListItem>
                        );
                      })}
                    </ul>
                    <Link
                      style={{
                        backgroundColor: pricing.packageBorderColor.hex,
                      }}
                      className="button book"
                      to={`/group-pricing/${pricing.slug}`}
                    >
                      Book
                    </Link>
                  </figure>
                ) : null}
                {/* Turn Package On or Off End */}
              </div>
              {/* // Pricing Div Wrapper Ends */}
            </Slide>
          ))}
        </PricingCard>

        <TestimonialsScroll id="testimonials"></TestimonialsScroll>

        <Testimonials className="testimonaials">
          <HeaderTwo>{data.datoCmsHome.testimonials}</HeaderTwo>
          {data.allDatoCmsTestimonial.edges.map(({ node: testimonial }) => (
            <Slide key={testimonial.id} right>
              <TestimonialCard testimonial={testimonial} />
            </Slide>
          ))}
        </Testimonials>
      </Layout>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexQuery {
    allDatoCmsPricing(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          id
          title
          slug
          priceAmount
          lengthOfSession
          numberOfSessions
          turnPackageOnOrOff
          packageSavings
          promoWide
          details {
            id
            task
          }
          packageBorderColor {
            hex
          }
          packageShadowColor {
            hex
          }
        }
      }
    }
    allDatoCmsGroupPricing(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          id
          title
          slug
          priceAmount
          lengthOfSession
          numberOfSessions
          turnPackageOnOrOff
          packageSavings
          promoWide
          details {
            id
            task
          }
          packageBorderColor {
            hex
          }
          packageShadowColor {
            hex
          }
        }
      }
    }
    datoCmsHome {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      topGradientColor {
        rgb
        hex
      }
      bottomGradientColor {
        rgb
        hex
      }
      copyright
      individualPricing
      groupPricing
      testimonials
    }
    allDatoCmsTestimonial(sort: { fields: shortQuote, order: ASC }) {
      edges {
        node {
          shortQuote
          fullLengthQuote
          name
          id
          imageOn
          backgroundColor {
            hex
          }
          testimonialImage {
            url
            fluid(maxWidth: 1200) {
              ...GatsbyDatoCmsFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`;
