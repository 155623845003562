import React, { useState, useRef, useLayoutEffect } from "react";
//import { useInView } from "react-intersection-observer"; //Needed for map
import PropTypes from "prop-types";
import scrollTo from "gatsby-plugin-smoothscroll";
import { Link } from "gatsby";
import { StaticQuery, graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import Slide from "react-reveal/Slide";
import LogRocket from "logrocket";
import BackgroundImage from "gatsby-background-image";
import "../styles/index.sass";
import Loader from "../components/loader";

LogRocket.init("oaksw4/jl-fit-production");

LogRocket.identify();

const HeroBanner = styled.section`
  .bg-cover {
    height: 100vh;
    display: grid;
    align-items: center;
    &:before {
      background-size: cover;
      background-attachment: fixed;
    }
    .block {
      width: 960px;
      margin: 0 auto;
      text-align: center;
      //background:#204D72;
      @media (max-width: 1050px) {
        width: 90%;
      }
      border-radius: 30px 30px 30px 30px;
    }
    .Cursor {
      display: none;
    }
    button {
      padding: 20px;
      border-radius: 30px 30px 30px 30px;
      color: #ffffff;
      text-transform: uppercase;
      border: none;
      background: green;
      font-size: 22px;
      margin-bottom: 40px;
      width: 200px;
      margin: 0 auto;
      cursor: pointer;
    }
    .Typist {
      background: red;
    }
    h1 {
      font-family: Nunito;
      text-align: center;
      color: #ffffff;
      text-transform: uppercase;
      font-size: 75px;
      margin-bottom: 15px;
      margin-top: 30px;
      font-weight: 100;
      letter-spacing: 3px;
      overflow: hidden; /* Ensures the content is not revealed until the animation */
      white-space: nowrap; /* Keeps the content on a single line */
      margin: 0 auto; /* Gives that scrolling effect as the typing happens */
      letter-spacing: 0.15em; /* Adjust as needed */
      animation: typing 3s steps(30, end);
      background: #204d72;
      margin-bottom: 20px;
      @media (max-width: 1050px) {
        font-size: 45px;
      }
      @media (max-width: 600px) {
        font-size: 22px;
      }
    }
  }
`;

const Nav = styled.nav`
  background: #ffffffbf;
  position: fixed;
  width: 100%;
  top: 0;
  button {
    list-style-type: none;
    display: inline;
    color: #000000;
    text-transform: uppercase;
    -webkit-letter-spacing: 5px;
    -moz-letter-spacing: 5px;
    -ms-letter-spacing: 5px;
    letter-spacing: 5px;
    font-size: 15px;
    font-weight: 900;
    background: none;
    border: none;
    font-family: "Karla";
    padding: 0;
    &:hover {
      cursor: pointer;
    }
    &:focus {
      outline: 0;
    }
  }
  a {
    height: 75px;
  }
  z-index: 10000;
  border-bottom: 3px solid black;
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center;
  transition: all 1.5s ease;
  &.white {
    background: #ffffff;
    transition: all 0.5s ease;
  }
  @media (max-width: 1350px) {
    display: block;
  }
  @media (max-width: 475px) {
    display: grid;
    grid-template-columns: 75px 1fr 75px;
    align-items: flex-start;
  }
  @media (max-width: 475px) {
    grid-template-columns: 70px 1fr 75px;
  }
  ul {
    text-align: right;
    &.main-nav {
      li {
        position: relative;
        padding: 15px;
        a {
          list-style-type: none;
          display: inline;
          color: #000000;
          text-transform: uppercase;
          -webkit-letter-spacing: 5px;
          -moz-letter-spacing: 5px;
          -ms-letter-spacing: 5px;
          letter-spacing: 5px;
          font-size: 15px;
          font-weight: 900;
          background: none;
          border: none;
        }
        span {
          cursor: pointer;
        }
      }
      @media (max-width: 1350px) {
        position: absolute;
        top: 163px;
        width: 100%;
        background: #ffffff;
        height: 100vh;
        left: -2000px;
        display: block;
        transition: left 0.5s ease;
        @media (max-width: 600px) {
          top: 155px;
        }
        @media (max-width: 475px) {
          top: 106px;
        }
        &.show {
          left: 0;
          padding: 50px 0 0 0;
          transition: left 0.5s ease;
          li {
            display: list-item;
            text-align: center;
          }
        }
        &.hide {
          left: -2000px;
          transition: left 0.5s ease;
          padding: 50px 0 0 0;
          li {
            display: list-item;
            text-align: center;
          }
        }
      }
    }
    li {
      list-style-type: none;
      display: inline;
      color: #000000;
      text-transform: uppercase;
      letter-spacing: 5px;
      font-size: 15px;
      font-weight: 900;
      a {
        color: #000000;
        text-decoration: none;
      }
    }
  }
`;

const EmailPhone = styled.ul`
  grid-column-start: 2;
  grid-column-end: 3;

  @media (max-width: 475px) {
    span {
      display: grid;
      grid-template-columns: 50px 50px;
      margin: 0 auto;
      justify-items: center;
    }
    &.mob-phone {
      text-align: center;
      padding: 0;
      li {
        display: list-item;
        &.facebook {
          display: none;
        }
        @media (max-width: 475px) {
          font-size: 12px;
          letter-spacing: 3px;
          padding: 15px 0 15px 0;
        }
        a {
          background: #54b554;
          padding: 10px;
          width: 100px;
          border-radius: 23px;
          color: #ffffff;
        }
      }
    }
  }
  li {
    padding: 0 15px 0 15px;
    &.lighter {
      font-weight: 100;
    }
  }
`;

const ImageLogo = styled.img`
  width: 75px;
  display: block;
  padding: 25px;
  @media (max-width: 1200px) {
    &.active-ham {
    }
  }
  @media (max-width: 600px) {
    width: 50px;
    padding: 10px;
  }
`;

const Hamburger = styled.span`
  padding: 35px 50px 0 0;
  font-size: 22px;
  @media (max-width: 475px) {
    padding: 10px 10px 0 0;
    width: 45px;
  }
  svg {
    height: 2em;
    width: 2em !important;
    @media (max-width: 475px) {
      height: 1.75em;
    }
  }
`;

const Footer = styled.div`
  background: ${({ background }) => background};
  padding: 100px;
  text-align: center;
  display: grid;
  justify-items: center;
  @media (max-width: 650px) {
    padding: 0;
  }
  h4 {
    font-size: 20px;
    font-weight: 100;
    letter-spacing: 2px;
    color: #ffffff;
    width: 625px;
    line-height: 32px;
    padding: 0 0 50px 0;
    @media (max-width: 750px) {
      width: 90%;
    }
  }
`;

const CheckBoxes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  label {
    display: grid;
    justify-items: center;
    text-align: center;
  }
  h4 {
    margin-top: 0;
    margin-bottom: 10px;
    color: #777777;
  }
  .pretty {
    margin-right: 0;
    font-size: 32px;
  }
  .pretty .state label:before {
    background: #ffffff;
  }
`;

const HomeForm = styled.form`
  background: lightgrey;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
  justify-items: center;
  padding: 75px;
  @media (max-width: 750px) {
    padding: 25px;
  }
  #contact {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  @media (max-width: 750px) {
    display: block;
  }
  h3 {
    span {
      color: red;
    }
  }
  label {
    width: 100%;
  }
  .checky {
    width: 100%;
  }
  input[type="text"],
  input[type="email"] {
    padding: 12px 12px;
    font-size: 20px;
    border: none;
    box-sizing: border-box;
    width: 100%;
    border-radius: 20px;
    &:focus {
      outline: 0;
    }
  }
  .text-area {
    grid-column-start: 1;
    grid-column-end: 3;
    width: 100%;
    textarea {
      height: 150px;
      border: none;
      box-sizing: border-box;
      width: 100%;
      border-radius: 20px;
      border: none;
      padding: 12px 12px;
      font-size: 20px;
      border: none;
      box-sizing: border-box;
      &:focus {
        outline: 0;
      }
    }
  }
  .submitHand {
    grid-column-start: 1;
    grid-column-end: 3;
    button {
      width: 100%;
      margin-top: 25px;
      border: none;
      background: green;
      color: #ffffff;
      padding: 20px;
      font-size: 32px;
      border-radius: 20px;
    }
    &.fail {
      a,
      span {
        display: block;
        text-align: center;
      }
      button {
        background: grey;
      }
    }
  }
`;

//DO NOT DELETE BELOW

// const MapWrapper = styled.div`
//   width:600px;
//   padding:0 0 50px 0;
//   height:450px;
//   @media (max-width:1200px){
//     width:100%;
//   }
//   .g-map{

//   }
// `

// const HeaderTwo = styled.h2`
//   display:block;
//   width:100%;
//   text-align:center;
//   grid-column-start: 1;
//   grid-column-end:   4;
//   margin-top:50px;
//   margin-bottom:150px;
//   font-size:50px;
//   font-weight:100;
//   text-transform:uppercase;
//   letter-spacing:3px;
//   @media (max-width: 1500px) {
//     grid-column-start: 1;
//     grid-column-end:   3;
//   }
//   @media (max-width: 960px) {
//     grid-column-start: initial;
//     grid-column-end:   initial;
//     font-size:30px;
//   }
//   @media (max-width: 500px) {
//     font-size:22px;
//   }
// `

//DO NOT DELETE ABOVE

const HeaderThree = styled.div`
  margin: 50px 0;
  h3 {
    display: block;
    width: 100%;
    text-align: center;
    grid-column-start: 1;
    grid-column-end: 4;
    margin: 20px;
    font-size: 40px;
    padding: 0;
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #ffffff;
    @media (max-width: 1500px) {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    @media (max-width: 960px) {
      grid-column-start: initial;
      grid-column-end: initial;
      font-size: 30px;
      width: 90%;
    }
    @media (max-width: 500px) {
      font-size: 18px;
    }
  }
`;

const AboutSection = styled.section`
  background: #868686;
  background: ${({ background }) => background};
  padding: 150px 0;
  @media (max-width: 600px) {
    padding: 0;
  }
`;

const AboutJlfit = styled.section`
  display: grid;
  justify-items: center;
  width: 50%;
  margin: 0 auto;
  @media (max-width: 1350px) {
    width: 75%;
  }
  @media (max-width: 960px) {
    width: 100%;
  }
  @media (max-width: 650px) {
    padding: 25px 0 50px 0;
  }
  div {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    text-align: center;
    p {
      font-size: 22px;
      line-height: 38px;
      width: 75%;
      margin: 0 auto;
      color: lightgray;
      letter-spacing: 1px;
    }
  }
`;

const ThankYou = styled.div`
  margin: 0 auto;
  width: 50%;
  text-align: center;
  padding: 150px;
`;
const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 150px;
  background: #adadad;
  @media (max-width: 1350px) {
    grid-template-columns: 1fr;
  }
  h2 {
    color: #ffffff;
    align-self: center;
    font-size: 40px;
    text-transform: uppercase;
    letter-spacing: 3px;
  }
`;

const Header = styled.h2`
  text-align: center;
`;

const LogoTagline = styled.img`
  margin: 0 auto;
  width: 150px;
`;

const LogoTaglineWrapper = styled.div`
  display: grid;
  align-items: center;
  @media (max-width: 600px) {
    padding-top: 100px;
  }
`;

// *Scroll Function*

const isBrowser = typeof window !== `undefined`;

function getScrollPosition({ element, useWindow }) {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element.current : document.body;
  const position = target.getBoundingClientRect();

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top };
}

// *Scroll Function Ends*

// *hook*

export function useScrollPosition(effect, deps, element, useWindow, wait) {
  const position = useRef(getScrollPosition({ useWindow }));

  let throttleTimeout = null;

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow });
    effect({ prevPos: position.current, currPos });
    position.current = currPos;
    throttleTimeout = null;
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, deps);
}

// *hook ends*

const TemplateWrapper = ({ children, props }) => {
  // *running function*

  const [hideOnScroll, setHideOnScroll] = useState(false);
  const shadowStyle = hideOnScroll ? "white" : "";

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y < -100;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  //hamburger menu

  const [state, setState] = useState(false);

  function toggle() {
    setState(!state);
  }

  //********FORM FUNCTION ***********/

  const [formState, setFormState] = React.useState({
    name: "",
    package: "",
    email: "",
    subject: "",
    message: "",
    reasons: {
      weightLoss: false,
      strength: false,
      sport: false,
      other: false,
    },
  });

  const formStateCheck = formState;

  const checkValidationName = () => {
    if (formStateCheck.name === "") {
      setShowMessageName(true);
    } else {
      setShowMessageName(false);
    }
  };

  const checkValidationEmail = () => {
    if (formStateCheck.email === "") {
      setShowMessageEmail(true);
    } else {
      setShowMessageEmail(false);
    }
  };

  const checkValidationSubject = () => {
    if (formStateCheck.subject === "") {
      setShowMessageSubject(true);
    } else {
      setShowMessageSubject(false);
    }
  };

  const checkValidationMessage = () => {
    if (formStateCheck.message === "") {
      setShowMessageMessage(true);
    } else {
      setShowMessageMessage(false);
    }
  };

  const checkValidationSubmit = () => {
    const formStateCheck = formState;

    if (
      formStateCheck.name !== "" &&
      formStateCheck.email !== "" &&
      formStateCheck.subject !== "" &&
      formStateCheck.message !== ""
    ) {
      setFormInvalid(true);
    }
  };

  const onChange = (e) => {
    if (e.target.type === "checkbox") {
      const changedReason = e.target.getAttribute("name");
      setFormState({
        ...formState,
        reasons: {
          ...formState.reasons,
          [changedReason]: !formState.reasons[changedReason],
        },
      });
    } else {
      setFormState({ ...formState, [e.target.name]: e.target.value });
    }

    checkValidationSubmit();
  };

  const prepareDataForApi = (formData) => {
    const newFormData = Object.assign({}, formData); // optional if passed cloned copy of formData object or you can also use lodash cloneDeep
    newFormData.data = {
      reasonArray: [],
    };

    Object.keys(newFormData.reasons).forEach((key) => {
      if (newFormData.reasons[key]) {
        newFormData.data.reasonArray.push({ reason: key });
      }
    });

    delete newFormData.reasons;

    return newFormData;
  };

  //Form Errors and Hiding and Showing

  const [form, setForm] = useState(true);

  function showForm(e) {
    e.preventDefault();
    setForm(true);
  }

  const [formFail, setFormFail] = useState(true);

  function formFailReset(e) {
    e.preventDefault();
    setFormFail(true);
  }

  const [formInvalid, setFormInvalid] = useState(false);

  // const [nameVal, setNameVal] = useState(false);

  const [showMessageName, setShowMessageName] = useState(false);

  const [showMessageEmail, setShowMessageEmail] = useState(false);

  const [showMessageSubject, setShowMessageSubject] = useState(false);

  const [showMessageMessage, setShowMessageMessage] = useState(false);

  const [loading, setLoading] = useState(true);

  //End

  // Try Catch

  //End

  const submitForm = async (e) => {
    e.preventDefault();

    //All validation
    if (
      formState.name === "" ||
      formState.email === "" ||
      formState.subject === "" ||
      formState.message === ""
    ) {
      setFormInvalid(false);
    } else {
      setFormInvalid(true);

      const newFormData = prepareDataForApi(formState);

      try {
        setLoading(false);
        setForm(false);
        const response = await fetch("/.netlify/functions/sendmail", {
          method: "POST",
          body: JSON.stringify(newFormData),
        }).then(() => {
          setLoading(true);
        });

        if (!response.ok) {
          setFormFail(false);

          return;
        }

        console.log("success email");
      } catch (e) {
        console.log("error");
      }
    }
  };

  //**********FORM FUNCTION ENDS*****/

  //**********Google Maps Variables*****/

  //DO NOT DELETE

  // const [ref, inView, entry] = useInView({
  //   /* Optional options */
  //   threshold: 0,
  //   triggerOnce: true
  // })

  // const style = {
  //   width: '100%',
  //   height: '400px',
  //   position: 'relative',
  // }

  // const coords = { lat: 53.541581, lng: -113.295608 };

  // const defaultProps = {
  //   center:{
  //     lat: 53.541581,
  //     lng: -113.295608
  //   },
  //   zoom:12
  // };

  //**********Google maps varaibles end*** */

  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          datoCmsSite {
            globalSeo {
              siteName
            }
            faviconMetaTags {
              ...GatsbyDatoCmsFaviconMetaTags
            }
          }
          datoCmsHome {
            logoTagline {
              url
            }
            footerBackgroundColor {
              hex
            }
            aboutJlFitBgColor {
              hex
            }
            seoMetaTags {
              ...GatsbyDatoCmsSeoMetaTags
            }
            aboutMeNode {
              childMarkdownRemark {
                html
              }
            }
            aboutJlFitContentNode {
              childMarkdownRemark {
                html
              }
            }
            introHeader
            copyright
            legalCopy
            contactMe
            aboutMeHeader
            aboutJlFit
            mapHeader
            homeHeroImage {
              url
              title
              fluid(maxWidth: 1200) {
                ...GatsbyDatoCmsFluid_noBase64
              }
            }
            topGradientColor {
              rgb
              hex
            }
            bottomGradientColor {
              rgb
              hex
            }
          }
          datoCmsHeader {
            logo {
              url
            }
          }
          datoCmsSocialProfile {
            facebookUrl
            email
            phoneNumber
          }
        }
      `}
      render={(data) => {
        const backgroundFluidImageStack = [
          data.datoCmsHome.homeHeroImage.fluid,
          `linear-gradient(${data.datoCmsHome.topGradientColor.hex}, ${data.datoCmsHome.bottomGradientColor.hex})`,
        ].reverse();

        return (
          <div className="container">
            <HelmetDatoCms
              favicon={data.datoCmsSite.faviconMetaTags}
              seo={data.datoCmsHome.seoMetaTags}
              title="test"
            >
              <meta
                name="keywords"
                content="Sherwood Park, Personal Trainer, Personal Training, Gym Trainer"
              />
            </HelmetDatoCms>
            {/* <Snowfall /> */}
            {/* <Img fluid={data.datoCmsHome.homeHeroImage.fluid}/> */}
            <Nav className={`header ${shadowStyle}`}>
              <Link to="/">
                <ImageLogo
                  className={state ? `active-ham` : ``}
                  src={data.datoCmsHeader.logo.url}
                />
              </Link>
              <ul className={state ? `main-nav show` : `main-nav hide`}>
                <li>
                  <button
                    onClick={() => {
                      scrollTo("#pricing");
                      toggle();
                    }}
                  >
                    Individual Pricing
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      scrollTo("#groupPricing");
                      toggle();
                    }}
                  >
                    Group Pricing
                  </button>
                </li>

                <li>
                  <button
                    onClick={() => {
                      scrollTo("#testimonials");
                      toggle();
                    }}
                  >
                    Testimonials
                  </button>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/availability">Availability</Link>
                </li>
                <li>
                  <button
                    onClick={() => {
                      scrollTo("#contact");
                      toggle();
                    }}
                  >
                    Contact
                  </button>
                </li>
              </ul>

              <EmailPhone
                className={state ? `mob-phone active-ham` : `mob-phone`}
              >
                <Hamburger onClick={toggle} className="hamTog">
                  <FontAwesomeIcon icon={faBars} />
                </Hamburger>

                <li className="lighter">
                  <a href={`mailto:` + data.datoCmsSocialProfile.email}>
                    {data.datoCmsSocialProfile.email}
                  </a>
                </li>
                <li className="lighter">
                  <a href={`tel:` + data.datoCmsSocialProfile.phoneNumber}>
                    {data.datoCmsSocialProfile.phoneNumber}
                  </a>
                </li>
                <span>
                  <li className="facebook">
                    <a href={data.datoCmsSocialProfile.facebookUrl}>
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                  </li>
                </span>
              </EmailPhone>
            </Nav>
            {/* <div className="container__mobile-header">
          <div className="mobile-header">
            <div className="mobile-header__menu">
              <Link to="#" data-js="toggleSidebar" />
            </div>
            <div className="mobile-header__logo">
              <Link to="/">{data.datoCmsSite.globalSeo.siteName}</Link>
            </div>
          </div>
        </div> */}
            <HeroBanner>
              <BackgroundImage
                Tag="section"
                fluid={backgroundFluidImageStack}
                className="bg-cover"
              >
                <div className="block">
                  <h1>{data.datoCmsHome.introHeader}</h1>

                  <button
                    onClick={() => {
                      scrollTo("#pricing");
                    }}
                  >
                    Pricing
                  </button>
                </div>
              </BackgroundImage>
            </HeroBanner>
            {/* <HeroBanner background={data.datoCmsHome.homeHeroImage.url} topGradient={data.datoCmsHome.topGradientColor.hex} bottomGradient={data.datoCmsHome.bottomGradientColor.hex}>
      <Slide bottom><h1>{data.datoCmsHome.introHeader}</h1></Slide>
      
      
    </HeroBanner> */}
            {/* <ChatBot steps={steps} /> */}
            {/* <Zoom>
          <Intro>
            <h2>{data.datoCmsHome.introHeader}</h2>
            <p>{data.datoCmsHome.introText}</p>
          </Intro>
        </Zoom> */}
            {/* About Section Starts Here */}
            {data.datoCmsHome.aboutJlFitBgColor ? (
              <AboutSection
                background={data.datoCmsHome.aboutJlFitBgColor.hex}
                id="about"
              >
                <LogoTaglineWrapper>
                  <LogoTagline src={data.datoCmsHome.logoTagline.url} />
                </LogoTaglineWrapper>

                <AboutJlfit>
                  <div>
                    <HeaderThree
                      dangerouslySetInnerHTML={{
                        __html: data.datoCmsHome.aboutJlFit,
                      }}
                    />
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          data.datoCmsHome.aboutJlFitContentNode
                            .childMarkdownRemark.html,
                      }}
                    />
                  </div>
                </AboutJlfit>
              </AboutSection>
            ) : (
              <AboutSection background="#000000" id="about">
                <LogoTaglineWrapper>
                  <LogoTagline src={data.datoCmsHome.logoTagline.url} />
                </LogoTaglineWrapper>

                <AboutJlfit>
                  <div>
                    <HeaderThree
                      dangerouslySetInnerHTML={{
                        __html: data.datoCmsHome.aboutJlFit,
                      }}
                    />
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          data.datoCmsHome.aboutJlFitContentNode
                            .childMarkdownRemark.html,
                      }}
                    />
                  </div>
                </AboutJlfit>
              </AboutSection>
            )}
            {/* About section ends here */}
            <div className="center-me">{children}</div>
            {/* FORM STARTS HERE */}
            <div id="contact"></div>
            <FormWrapper>
              <Header>{data.datoCmsHome.contactMe}</Header>

              {form ? (
                <Slide right>
                  <HomeForm onSubmit={submitForm} autoComplete="off">
                    {/* <input type="text" name="package" value={data.datoCmsPricing.title} /> */}
                    <label for="name">
                      <h3>Name{showMessageName && <span> *Required</span>}</h3>
                      <input
                        type="text"
                        name="name"
                        value={formState.name}
                        onChange={onChange}
                        onKeyUp={checkValidationName}
                        autoComplete="off"
                      />
                    </label>
                    <label for="email">
                      <h3>
                        Email{showMessageEmail && <span> *Required</span>}
                      </h3>
                      <input
                        type="email"
                        name="email"
                        value={formState.email}
                        onChange={onChange}
                        onKeyUp={checkValidationEmail}
                        autoComplete="off"
                      />
                    </label>
                    <label for="subject">
                      <h3>
                        Subject{showMessageSubject && <span> *Required</span>}
                      </h3>
                      <input
                        type="text"
                        name="subject"
                        value={formState.subject}
                        onChange={onChange}
                        onKeyUp={checkValidationSubject}
                      />
                    </label>
                    <div className="checky">
                      <h3>Reasons for wanting to train</h3>
                      <CheckBoxes>
                        <label for="weightLoss">
                          <h4>Weight Loss</h4>
                          <div className="pretty p-smooth p-curve p-fill">
                            <input
                              type="checkbox"
                              name="weightLoss"
                              reason="Weight Loss"
                              checked={formState.reasons.weightLoss}
                              onChange={onChange}
                            />
                            <div className="state p-primary">
                              <label for="weightLoss"></label>
                            </div>
                          </div>
                        </label>
                        <label for="strength">
                          <h4>Strength</h4>
                          <div className="pretty p-smooth p-curve p-fill">
                            <input
                              type="checkbox"
                              name="strength"
                              reason="Strength"
                              checked={formState.reasons.strength}
                              onChange={onChange}
                            />
                            <div className="state p-primary">
                              <label for="strength"></label>
                            </div>
                          </div>
                        </label>
                        <label for="sport">
                          <h4>Sport</h4>
                          <div className="pretty p-smooth p-curve p-fill">
                            <input
                              type="checkbox"
                              name="sport"
                              reason="Sport"
                              checked={formState.reasons.sport}
                              onChange={onChange}
                            />
                            <div className="state p-primary">
                              <label for="sport"></label>
                            </div>
                          </div>
                        </label>
                        <label for="other">
                          <h4>Other</h4>
                          <div className="pretty p-smooth p-curve p-fill">
                            <input
                              type="checkbox"
                              name="other"
                              reason="Other"
                              checked={formState.reasons.other}
                              onChange={onChange}
                            />
                            <div className="state p-primary">
                              <label for="other"></label>
                            </div>
                          </div>
                        </label>
                      </CheckBoxes>
                    </div>

                    <div className="text-area">
                      <label for="message">
                        <h3>
                          Message{showMessageMessage && <span> *Required</span>}
                        </h3>
                        <textarea
                          name="message"
                          value={formState.message}
                          onChange={onChange}
                          onKeyUp={checkValidationMessage}
                        />
                      </label>
                    </div>

                    {formFail ? (
                      <label
                        className={
                          formInvalid ? `submitHand` : `submitHand fail`
                        }
                      >
                        {formInvalid ? (
                          <button type="submit">Submit</button>
                        ) : (
                          <button disabled type="submit">
                            Submit
                          </button>
                        )}
                      </label>
                    ) : (
                      <label className="submitHand fail">
                        <span>Something Went Wrong</span>
                        <a onClick={formFailReset} href="#">
                          Click to reset form
                        </a>
                        <button disabled type="submit">
                          Submit
                        </button>
                      </label>
                    )}
                  </HomeForm>
                </Slide>
              ) : (
                <>
                  {loading ? (
                    <ThankYou>
                      <div>
                        <p>Thank you your message has been sent</p>
                      </div>

                      <a onClick={showForm} href="#">
                        Send Again
                      </a>
                    </ThankYou>
                  ) : (
                    <ThankYou>
                      <Loader />
                    </ThankYou>
                  )}
                </>
              )}
            </FormWrapper>
            {/* fORM ENDS HERE */}
            {/* About section goes here */}
            {/*  */}
            {/* <Footer ref={ref} className="copyright"> */}
            {data.datoCmsHome.footerBackgroundColor && (
              <Footer
                background={data.datoCmsHome.footerBackgroundColor.hex}
                className="copyright"
              >
                <h4>{data.datoCmsHome.mapHeader}</h4>
                <p>{data.datoCmsHome.copyright}</p>
                <p>{data.datoCmsHome.legalCopy}</p>
              </Footer>
            )}
          </div>
        );
      }}
    />
  );
};

TemplateWrapper.propTypes = {
  children: PropTypes.object,
};

export default TemplateWrapper;
